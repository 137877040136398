import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import {
  getLiveEvents,
  registerLiveEvents,
} from "../../../redux/actions/conference.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import {
  formatDate,
  formattTime,
  timeAgo,
  getStatusFromDate,
  isMeetingEnd,
} from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

import {
  CLEAR_LOADING,
  SET_LOADING,
} from "../../../redux/constants/globalActionTypes";

// import Brochure from "../../../img/live_events/HSICON-2024-SCHEDULE.pdf";

import * as payment from "../../../services/razorpay";

const LiveConference = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.meeting.meetingsList);
  const pageNo = useSelector((state) => state.meeting.page);
  const hasMore = useSelector((state) => state.meeting.hasMore);

  const liveConferenceList = useSelector(
    (state) => state.conference.liveConference
  );

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  const [isPayementCheck, setIsPayementCheck] = useState(true);
  const [cancelData, setCancelData] = useState(null);

  var intervalId;

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    const getConference = async () => {
      dispatch(getLiveEvents({ page: 1 }, true));
    };

    getConference();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("cancelData", cancelData);

      if (cancelData) {
        payment.closeWindow(cancelData);

        event.preventDefault();
        event.returnValue = ""; // For legacy browsers
      }
    };

    // Add event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [cancelData]);

  const registerNow = async (id) => {
    dispatch(registerLiveEvents({ id: id })).then(async (response) => {
      if (response == "") {
        window.location.reload();
      } else {
        response.type = "LIVE_EVENTS";
        if (response.members.transaction_status === "INIT") {
          setCancelData(response);
          await payment.makePayment(response);
        }
      }
    });
  };

  const handleOpenPdf = () => {
    // PDF file located in the public folder
    const pdfUrl =
      "https://d27m4xh8yh8co7.cloudfront.net/staging/conference/b7th1juevgm236nwpl.pdf";
    window.open(pdfUrl, "_blank"); // Opens the PDF in a new tab
  };

  return (
    <Root>
      <Breadcrumb
        title={"Live Conference"}
        fieldOne="Dashboard"
        fieldTwo="Live Conference"
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">Live Conference</h2>
              <p>Total {liveConferenceList.length} Conference</p>
            </div>
            <div className=" d-lg-block">
              <button
                title="Recording of Conference"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/recorded-conference-workshop");
                }}
              >
                Recording of Conference{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {liveConferenceList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        {/* <th>Attendies</th> */}
                        <th>Fees</th>
                        <th>Status</th>
                        <th className="text-end" style={{ width: "200px" }}>
                          &nbsp;
                        </th>
                      </thead>
                      <tbody>
                        {liveConferenceList.map((val, index) => (
                          <tr>
                            <td className="fw-bold">
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Topic:
                              </p>
                              <h6 className="mb-0">{val.title}</h6>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Date &amp; Time:
                              </p>
                              <p className="mb-0">
                                {formatDate(val.startDate)} to{" "}
                                {formatDate(val.endDate)}{" "}
                                {formattTime(val.startTime)} to{" "}
                                {formattTime(val.endTime)}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                Hosted by:
                              </p>
                              <div className="author d-flex">
                                {/* <span className="user-image-wrap user-image-md me-md-3 mb-3 mb-md-0">
                                  <img
                                    src={require("../../../img/user.png")}
                                    alt=""
                                    title="Surgeon Profile"
                                  />
                                </span> */}
                                <span className="author-details">
                                  <p className="fw-bold mb-0">
                                    {/* <Link to={`/surgeon-profile/${val.user_details._id}`}> */}
                                    {val.venue}
                                    {/* </Link> */}
                                  </p>
                                  <p className="text-muted mb-0">{`${val.host}`}</p>
                                </span>
                              </div>
                            </td>
                            {/* <td>{`${val.attendies} Members`}</td> */}
                            <td>
                              <strong>
                                {val.fees > 0 ? `Rs. ${val.fees}` : `Free`}
                              </strong>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                Status:
                              </p>
                              {/* {(() => {
                                const dataResult = getStatusFromDate(val.startDate, val.endDate);
                                return <span className={`badge badge-${dataResult.color}`}>{`${dataResult.status}`}</span>;
                              })()} */}
                              <span
                                className={`badge badge-info`}
                              >{`Upcoming`}</span>
                            </td>
                            <td className="text-left text-lg-end">
                              <button
                                rel="noopener noreferrer"
                                className="btn btn-primary mb-2 m-2 btn-sm"
                                onClick={() => handleOpenPdf()}
                              >
                                Brochure{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button>
                              {!isMeetingEnd(val.endDate) ? (
                                val.paymentStatus &&
                                val.userTransactionStatus === "COMPLETED" ? (
                                  val.joinUrl != null ? (
                                    <>
                                      <a
                                        href={val.joinUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary mb-2 btn-sm"
                                      >
                                        Join Hall-A{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </a>
                                      <a
                                        href={val.joinUrlTwo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary mb-2 btn-sm"
                                      >
                                        Join Hall-B{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                      </a>
                                    </>
                                  ) : (
                                    <button
                                      className="btn btn-primary mb-2 btn-sm"
                                      disabled="true"
                                    >
                                      Join{" "}
                                      <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                  )
                                ) : !val.paymentStatus ? (
                                  val.userTransactionStatus === "PROCESSING" ? (
                                    <span
                                      className={`badge badge-warning`}
                                    >{`Payment Processing...`}</span>
                                  ) : (
                                    <button
                                      rel="noopener noreferrer"
                                      className="btn btn-success mb-2 btn-sm"
                                      onClick={() => registerNow(val._id)}
                                    >
                                      Register Now{" "}
                                      <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                  )
                                ) : null
                              ) : null}

                              {/* <button
                                onClick={() => {
                                  navigate(`/cme-rte/${val._id}`);
                                }}
                                className="mb-2 btn btn-link btn-sm"
                              >
                                Details{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default LiveConference;
