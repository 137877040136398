import Roles from "../views/pages/Adminroles/Roles";
import BrowseAssocGroups from "../views/pages/AssociationGroups/BrowseAssocGroups";
import CreateAssocGroups from "../views/pages/AssociationGroups/CreateAssocGroups";
import MyAssocgroups from "../views/pages/AssociationGroups/MyAssocgroups";
import AttendCmeRtm from "../views/pages/CME/AttendCmeRtm";
import InvitationsCmeRtm from "../views/pages/CME/InvitationsCmeRtm";
import MyCmeRtm from "../views/pages/CME/MyCmeRtm";
import ScheduleCmeRtm from "../views/pages/CME/ScheduleCmeRtm";
import EditCmeRtm from "../views/pages/CME/EditCmeRtm";
import CmeRtmDetails from "../views/pages/CME/CmeRtmDetails";
import AttendConfWork from "../views/pages/Conf-Workshop/AttendConfWork";
import BrowseConfWork from "../views/pages/Conf-Workshop/BrowseConfWork";
import MyConfWork from "../views/pages/Conf-Workshop/MyConfWork";
import PostConfWork from "../views/pages/Conf-Workshop/PostConfWork";
import Dashboard from "../views/pages/Dashboard/Dashboard";
import AttendLiveSergery from "../views/pages/LiveSurgery/AttendLiveSurgery";
import BrowseLiveSurgery from "../views/pages/LiveSurgery/BrowseLiveSurgery";
import MyLiveSurgery from "../views/pages/LiveSurgery/MyLiveSurgery";
import OrganizeLiveSurgery from "../views/pages/LiveSurgery/OrganizeLiveSurgery";
import BrowseObserverCourse from "../views/pages/Observership/BrowseObserverCourse";
import CreatePatientProfile from "../views/pages/PatientReferral/CreatePatientProfile";
import ReferYourPatient from "../views/pages/PatientReferral/ReferYourPatient";
import ReferalsReceived from "../views/pages/PatientReferral/ReferalsReceived";
import RefferedPatients from "../views/pages/PatientReferral/RefferedPatients";
import BrowseTutorial from "../views/pages/Tutorial/BrowseTutorial";
import MyTutorial from "../views/pages/Tutorial/MyTutorial";
import UploadTutorial from "../views/pages/Tutorial/UploadTutorial";
import TutorialDetails from "../views/pages/Tutorial/TutorialDetails";
import BrowseSurgeryVideo from "../views/pages/Videos/BrowseSurgeryVideo";
import EditSurgeryVideo from "../views/pages/Videos/EditSurgeryVideo";
import MySurgeryVideo from "../views/pages/Videos/MySurgeryVideo";
import UploadSurgeryVideo from "../views/pages/Videos/UploadSurgeryVideo";
import AttendObserverCourse from "../views/pages/Observership/AttendObserverCourse";
import PostObserverCourse from "../views/pages/Observership/PostObserverCourse";
import MyObserverCourse from "../views/pages/Observership/MyObserverCourse";
import VideoDetails from "../views/pages/Videos/VideoDetails";
import MyProfile from "../views/pages/Profile/MyProfile";
import PersonalInformation from "../views/pages/Profile/PersonalInformation";
import PatientReferralProfile from "../views/pages/Profile/PatientReferralProfile";
import ChangePassword from "../views/pages/Profile/ChangePassword";
import ObservershipDetailScreen from "../views/pages/Observership/ObservershipDetailScreen";
import LiveSurgeryDetails from "../views/pages/LiveSurgery/LiveSurgeryDetails";
import PatientInfo from "../views/pages/PatientReferral/PatientInfo";
import Notification from "../views/pages/Notification/Notification";
import AssociationGroupDetails from "../views/pages/AssociationGroups/AssociationGroupDetails";
import AssociationLiveSurgery from "../views/pages/AssociationGroups/AssociationLiveSurgery";
import AssociationTutorial from "../views/pages/AssociationGroups/AssociationTutorial";
import AssociationCme from "../views/pages/AssociationGroups/AssociationCme";
import AssociationConference from "../views/pages/AssociationGroups/AssociationConference";
import AssociationSurgeryVideo from "../views/pages/AssociationGroups/AssociationSurgeryVideo";
import MyAssociationLiveSurgery from "../views/pages/AssociationGroups/MyAssociationLiveSurgery";
import MyAssociationSurgeryVideo from "../views/pages/AssociationGroups/MyAssociationSurgeryVideo";
import MyAssociationTutorial from "../views/pages/AssociationGroups/MyAssociationTutorial";
import MyAssociationCME from "../views/pages/AssociationGroups/MyAssociationCME";
import MyAssociationConference from "../views/pages/AssociationGroups/MyAssociationConference";
import AssociationUpdates from "../views/pages/AssociationGroups/AssociationUpdates";
import AssociationMembers from "../views/pages/AssociationGroups/AssociationMembers";
import ConfDetails from "../views/pages/Conf-Workshop/ConfDetails";
import RequestMentorship from "../views/pages/Telementorship/RequestMentorship";
import ContactUs from "../views/pages/ContactUs/ContactUs";
import RequestedMentorship from "../views/pages/Telementorship/RequestedMentorship";
import SurgeonProfile from "../views/pages/PatientReferral/SurgeonProfile";

import BrowseRecordedConference from "../views/pages/AssociationGroups/RecordedConference/BrowseRecordedConference";
import EditRecordedConference from "../views/pages/AssociationGroups/RecordedConference/EditRecordedConference";
import MyRecordedConference from "../views/pages/AssociationGroups/RecordedConference/MyRecordedConference";
import UploadRecordedConference from "../views/pages/AssociationGroups/RecordedConference/UploadRecordedConference";
import RecordedConferenceDetails from "../views/pages/AssociationGroups/RecordedConference/RecordedConferenceDetails";

import AssociationCMEInvitation from "../views/pages/AssociationGroups/InvitationCME";
import AssociationAttendLiveSurgery from "../views/pages/AssociationGroups/AttendLiveSurgery";

import MeetingError from "../views/pages/CME/MeetingError";
import ActivateZoom from "../views/pages/CME/ActivateZoom";
import LiveSurgeryError from "../views/pages/LiveSurgery/LiveSurgeryError";

import AddPatient from "../views/pages/PatientRegistry/AddPatient";
import ViewPatients from "../views/pages/PatientRegistry/ViewPatients";
import AddProcedure from "../views/pages/PatientRegistry/AddProcedure";
import TrackPatients from "../views/pages/PatientRegistry/TrackPatients";
import ComingSoon from "../views/pages/ComingSoon/ComingSoon";

import LiveConference from "../views/pages/Conf-Workshop/LiveConference";
import RecordedConference from "../views/pages/Conf-Workshop/RecordedConference";

//Create Patient referral profile
import CreateReferral from "../views/pages/PatientReferral/CreateReferral";

const privateRoutes = [
  {
    path: "/admin-roles",
    component: <Roles />,
    title: "CRM Admin | Role management",
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
    title: "Surgic Share | Dashboard",
  },
  {
    path: "/browseLiveSurgery",
    component: <BrowseLiveSurgery />,
    title: "Surgic Share | Browse Live Surgery",
  },
  {
    path: "/attendLiveSurgery",
    component: <AttendLiveSergery />,
    title: "Surgic Share | Attend Live Surgery",
  },
  {
    path: "/organizeLiveSurgery",
    component: <OrganizeLiveSurgery />,
    title: "Surgic Share | Organize Live Surgery",
  },
  {
    path: "/edit-livesurgery/:id",
    component: <OrganizeLiveSurgery />,
    title: "Surgic Share | Edit Live Surgery",
  },
  {
    path: "/edit-association-livesurgery/:id/:associationId",
    component: <OrganizeLiveSurgery />,
    title: "Surgic Share | Edit Live Surgery",
  },
  {
    path: "/myLiveSurgery",
    component: <MyLiveSurgery />,
    title: "Surgic Share | My Live Surgery",
  },
  {
    path: "/myAssociationLiveSurgery/:associationId",
    component: <MyAssociationLiveSurgery />,
    title: "Surgic Share | My Live Surgery",
  },
  {
    path: "/organizeLiveSurgery/:associationId",
    component: <OrganizeLiveSurgery />,
    title: "Surgic Share | Organize Live Surgery",
  },
  {
    path: "/browseObservership",
    component: <BrowseObserverCourse />,
    title: "Surgic Share | Browse observership Surgery",
  },
  {
    path: "/attendObservership",
    component: <AttendObserverCourse />,
    title: "Surgic Share | Attend Observership Courses",
  },
  {
    path: "/postObservership",
    component: <PostObserverCourse />,
    title: "Surgic Share | Post Observership Courses",
  },
  {
    path: "/myObservership",
    component: <MyObserverCourse />,
    title: "Surgic Share | My Observership Courses",
  },
  {
    path: "/observershipDetails/:id",
    component: <ObservershipDetailScreen />,
    title: "Surgic Share | My Observership Courses",
  },
  {
    path: "/browseSurgeryVideos",
    component: <BrowseSurgeryVideo />,
    title: "Surgic Share | Browse Surgery Videos",
  },
  {
    path: "/uploadSurgeryVideos",
    component: <UploadSurgeryVideo />,
    title: "Surgic Share | Upload Surgery Videos",
  },
  {
    path: "/editSurgeryVideos/:id",
    component: <EditSurgeryVideo />,
    title: "Surgic Share | Edit Surgery Videos",
  },
  {
    path: "/surgeryDetails/:id",
    component: <LiveSurgeryDetails />,
    title: "Surgic Share | Surgery Details",
  },
  {
    path: "/mySurgeryVideos",
    component: <MySurgeryVideo />,
    title: "Surgic Share | My Surgery Videos",
  },
  {
    path: "/myAssociationSurgeryVideos/:associationId",
    component: <MyAssociationSurgeryVideo />,
    title: "Surgic Share | My Surgery Videos",
  },

  {
    path: "/video-details/:id",
    component: <VideoDetails />,
    title: "Surgic Share | Videos Details",
  },
  {
    path: "/uploadSurgeryVideos/:associationId",
    component: <UploadSurgeryVideo />,
    title: "Surgic Share | Upload Surgery Videos",
  },
  {
    path: "/browseTutorials",
    component: <BrowseTutorial />,
    title: "Surgic Share | Browse Tutorial Videos",
  },
  {
    path: "/uploadTutorials",
    component: <UploadTutorial />,
    title: "Surgic Share | Upload Tutorial Videos",
  },
  {
    path: "/uploadTutorials/:associationId",
    component: <UploadTutorial />,
    title: "Surgic Share | Upload Tutorial Videos",
  },
  {
    path: "/editTutorials/:id",
    component: <UploadTutorial />,
    title: "Surgic Share | Edit Tutorial Videos",
  },
  {
    path: "/myTutorials",
    component: <MyTutorial />,
    title: "Surgic Share | My Tutorial Videos",
  },
  {
    path: "/myAssociationTutorials/:associationId",
    component: <MyAssociationTutorial />,
    title: "Surgic Share | My Tutorial Videos",
  },

  {
    path: "/tutorial-details/:id",
    component: <TutorialDetails />,
    title: "Surgic Share | Tutorial Details",
  },
  {
    path: "/attendCmeRtm",
    component: <AttendCmeRtm />,
    title: "Surgic Share | Attend CME (Continuing Medical Education)",
  },
  {
    path: "/cme-rtm-invitations",
    component: <InvitationsCmeRtm />,
    title: "Surgic Share | Invitations CME (Continuing Medical Education)",
  },
  {
    path: "/scheduleLiveCmeRtm",
    component: <ScheduleCmeRtm />,
    title: "Surgic Share | Schedule CME (Continuing Medical Education)",
  },
  {
    path: "/scheduleLiveCmeRtm/:associationId",
    component: <ScheduleCmeRtm />,
    title: "Surgic Share | Schedule CME (Continuing Medical Education)",
  },
  {
    path: "/edit-cme-rte/:id",
    component: <ScheduleCmeRtm />,
    title: "Surgic Share | Edit CME (Continuing Medical Education)",
  },
  {
    path: "/edit-association-cme-rte/:id/:associationId",
    component: <ScheduleCmeRtm />,
    title: "Surgic Share | Edit CME (Continuing Medical Education)",
  },
  {
    path: "/cme-rte/:id",
    component: <CmeRtmDetails />,
    title: "Surgic Share | CME (Continuing Medical Education) Details",
  },
  {
    path: "/myCmeRtm",
    component: <MyCmeRtm />,
    title: "Surgic Share | My CME (Continuing Medical Education)",
  },
  {
    path: "/myAssociationCmeRtm/:associationId",
    component: <MyAssociationCME />,
    title: "Surgic Share | My CME (Continuing Medical Education)",
  },

  {
    path: "/browseConfWorkshop",
    component: <BrowseConfWork />,
    title: "Surgic Share | Browse Conference, Workshop and Hands on Training",
  },
  {
    path: "/attendConfWorkshop",
    component: <AttendConfWork />,
    title: "Surgic Share | Attend Conference, Workshop and Hands on Training",
  },
  {
    path: "/postConfWorkshop",
    component: <PostConfWork />,
    title: "Surgic Share | Post Conference, Workshop and Hands on Training",
  },
  {
    path: "/editConfWorkshop/:id",
    component: <PostConfWork />,
    title: "Surgic Share | Post Conference, Workshop and Hands on Training",
  },
  {
    path: "/postConfWorkshop/:associationId",
    component: <PostConfWork />,
    title: "Surgic Share | Post Conference, Workshop and Hands on Training",
  },

  {
    path: "/myConfWorkshop",
    component: <MyConfWork />,
    title: "Surgic Share | My Conference, Workshop and Hands on Training",
  },
  {
    path: "/myAssociationConf/:associationId",
    component: <MyAssociationConference />,
    title: "Surgic Share | My Conference, Workshop and Hands on Training",
  },
  {
    path: "/confDetails/:id",
    component: <ConfDetails />,
    title: "Surgic Share | Conference Details, Workshop and Hands on Training",
  },

  {
    path: "/BrowseAssocGroups",
    component: <BrowseAssocGroups />,
    title: "Surgic Share | Browse Associations and Groups",
  },
  {
    path: "/AssociationDetails/:associationId",
    component: <AssociationGroupDetails />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/AssociationLiveSurgery/:associationId",
    component: <AssociationLiveSurgery />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/AssociationTutorial/:associationId",
    component: <AssociationTutorial />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/AssociationMeeting/:associationId",
    component: <AssociationCme />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/association-cme-invitation/:associationId",
    component: <AssociationCMEInvitation />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/association-attend-livesurgery/:associationId",
    component: <AssociationAttendLiveSurgery />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/AssociationConference/:associationId",
    component: <AssociationConference />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/AssociationVideos/:associationId",
    component: <AssociationSurgeryVideo />,
    title: "Surgic Share | Associations and Groups Details",
  },
  {
    path: "/myAssocGroups",
    component: <MyAssocgroups />,
    title: "Surgic Share | My Associations and Groups",
  },
  {
    path: "/myAssocUpdate/:associationId",
    component: <AssociationUpdates />,
    title: "Surgic Share | My Associations and Groups Updates",
  },
  {
    path: "/associationMembers/:associationId",
    component: <AssociationMembers />,
    title: "Surgic Share | My Associations and Groups Updates",
  },
  {
    path: "/createAssocGroups",
    component: <CreateAssocGroups />,
    title: "Surgic Share | Create Associations and Groups",
  },
  {
    path: "/editAssocGroups/:id",
    component: <CreateAssocGroups />,
    title: "Surgic Share | Update Associations and Groups",
  },
  {
    path: "/editObservership/:id",
    component: <PostObserverCourse />,
    title: "Surgic Share | Update Observership",
  },
  {
    path: "/referYourPatient",
    component: <ReferYourPatient />,
    title: "Surgic Share | Refer Your Patient",
  },
  {
    path: "/createPatientProfile",
    component: <CreatePatientProfile />,
    title: "Surgic Share | Create Patient Referral Profile",
  },
  {
    path: "/create-patient-referral",
    component: <CreateReferral />,
    title: "Surgic Share | Create Patient Referral Profile",
  },
  {
    path: "/my-profile",
    component: <CreatePatientProfile />,
    title: "Surgic Share | My Profile",
  },
  {
    path: "/referredPatient",
    component: <RefferedPatients />,
    title: "Surgic Share | Patients I Referred",
  },
  {
    path: "/patientInfo/:surgeonId",
    component: <PatientInfo />,
    title: "Surgic Share | Patients Information",
  },

  {
    path: "/referralReceived",
    component: <ReferalsReceived />,
    title: "Surgic Share | Patient Referrals Received",
  },
  {
    path: "/my-profile",
    component: <MyProfile />,
    title: "Surgic Share | My Profile",
  },
  {
    path: "/personal-information",
    component: <PersonalInformation />,
    title: "Surgic Share | Personal Information",
  },
  {
    path: "/patient-referral-profile",
    component: <PatientReferralProfile />,
    title: "Surgic Share | Patient Referral Profile",
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
    title: "Surgic Share | Change Password",
  },
  {
    path: "/notification",
    component: <Notification />,
    title: "Surgic Share | Notification",
  },
  {
    path: "/requestTelementorship",
    component: <RequestMentorship />,
    title: "Surgic Share | Telementorship",
  },
  {
    path: "/requestTelementorship/:userId",
    component: <RequestMentorship />,
    title: "Surgic Share | Telementorship",
  },
  {
    path: "/requestedTelementorship",
    component: <RequestedMentorship />,
    title: "Surgic Share | Telementorship",
  },
  {
    path: "/support",
    component: <ContactUs />,
    title: "Surgic Share | Contact Us",
  },
  {
    path: "/surgeon-profile/:id",
    component: <SurgeonProfile />,
    title: "Surgic Share | Surgeon Profile",
  },
  {
    path: "/meeting-request",
    component: <MeetingError />,
    title: "Surgic Share | Live Meeting",
  },
  {
    path: "/follow-steps",
    component: <ActivateZoom />,
    title: "Surgic Share | Create Meeting",
  },
  {
    path: "/live-surgery-request",
    component: <LiveSurgeryError />,
    title: "Surgic Share | Live Surgery",
  },

  //Recorded Conference

  {
    path: "/browse-recorded-conference/:associationId",
    component: <BrowseRecordedConference />,
    title: "Surgic Share | Browse Recorded Conference",
  },
  {
    path: "/upload-recorded-conference/:associationId",
    component: <UploadRecordedConference />,
    title: "Surgic Share | Upload Recorded Conference",
  },
  {
    path: "/edit-recorded-conference/:associationId/:id",
    component: <EditRecordedConference />,
    title: "Surgic Share | Edit Recorded Conference",
  },
  {
    path: "/my-recorded-conference/:associationId",
    component: <MyRecordedConference />,
    title: "Surgic Share | My Recorded Conference",
  },
  {
    path: "/recorded-conference/:id",
    component: <RecordedConferenceDetails />,
    title: "Surgic Share |  Recorded Conference Details",
  },

  //Patient Registry

  {
    path: "/add-new-patient",
    component: <AddPatient />,
    title: "Surgic Share | Add New Patient",
  },
  {
    path: "/view-patient",
    component: <ViewPatients />,
    title: "Surgic Share | View Patient",
  },
  {
    path: "/add-patient-procedure/:patientId",
    component: <AddProcedure />,
    title: "Surgic Share | View Patient",
  },
  {
    path: "/track-patient-procedure/:patientId",
    component: <TrackPatients />,
    title: "Surgic Share | View Patient",
  },

  {
    path: "/live-conference-workshop",
    component: <LiveConference />,
    title: "Surgic Share | Live Conference",
  },
  {
    path: "/recorded-conference-workshop",
    component: <RecordedConference />,
    title: "Surgic Share | Recorded Conference",
  },

  {
    path: "/coming-soon",
    component: <ComingSoon />,
    title: "Surgic Share | Coming Soon",
  },
];

export default privateRoutes;
