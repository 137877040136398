import React, { useEffect, useState } from "react";

import AssociationLogo from "../../../img/association-default.png";
import surgeonPic from "../../../img/surgeon-image.png";
import dash1 from "../../../img/icon-live-surgery.png";
import dash2 from "../../../img/icon-meeting.png";
import dash3 from "../../../img/icon-video.png";
import dash4 from "../../../img/icon-tutorial.png";
import dash5 from "../../../img/icon-observership-2.png";
import dash6 from "../../../img/icon-workshop.png";
import DashboardTab from "../../components/Dashboard/Card";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import { Link } from "react-router-dom";
import Root from "../../components/Root";
import { Button, Col, Row, Card } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";

import { useDispatch, useSelector } from "react-redux";
import { getAllDashboard } from "../../../redux/actions/dashboard.action";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import {
  timeAgo,
  formatDate,
  formattTime,
  getTimeFromDate,
  getStatusFromDate,
} from "../../../utils/core";
import moment from "moment";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const Dashboard = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const dashboardList = useSelector((state) => state.dashboard.dashboardList);
  const pageNo = useSelector((state) => state.dashboard.page);

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  useEffect(() => {
    dispatch(getAllDashboard({ page: 1 }, true));
  }, []);

  const registerConferenceNow = (url) => {
    window.open(url, "_blank");
    // dispatch(registerToConference({ id: id }));
  };

  return (
    <Root>
      <Breadcrumb
        title={"Dashboard"}
        items={[
          {
            name: "Home",
            routeTo: "/home",
          },
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
        ]}
      />
      <ContainerWrapper>
        {/* <ProfileComplete/> */}
        {/* <Row className="mt-2">
          <Col className={12}>
            <h2 className="h4 mb-1">Explore Surgic Share</h2>
            <p>
              Browse surgery videos, tutorials, attend live surgeries,
              observership and workshops.
            </p>
          </Col>
        </Row> */}
        <Row className="align-items-stretch dash_box dash_showcase">
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/attendCmeRtm"
              title="Attend or Schedule CME on Zoom"
              className="d-flex align-items-stretch w-100"
            >
              {/* <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start text-center text-md-start"> */}
              <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/cme-white.png")}
                    alt="CME"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/cme.png")}
                    alt="CME"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Organize / Attend
                    <br />
                    Web CME on Zoom{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">CME </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                  Attend CME <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          {/* <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/browseLiveSurgery"
              title="Organize/ Attend Live Surgery"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-livesurgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img src={require("../../../img/live-surgery-white.png")} alt="Live Surgery" width="44" className="d-none d-sm-block"/>
                  <img src={require("../../../img/live-surgery.png")} alt="Live Surgery" width="44" className="d-inline-block d-sm-none"/>
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Organize / Attend<br />Live Surgery{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">Live Surgery </h6>
                </div>
              
              </div>
            </Link>
          </Col> */}
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/browseSurgeryVideos"
              title="Post or Browse Surgery Videos"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/videos-white.png")}
                    alt="Surgery Videos"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/videos.png")}
                    alt="Surgery Videos"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Post / Browse Surgery Videos{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">
                    Surgery Videos{" "}
                  </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                  Surgery Videos <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/browseTutorials"
              title="Post or Browse Tutorials"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/tutorials-white.png")}
                    alt="Tutorials"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/tutorials.png")}
                    alt="Tutorials"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Post / Browse Tutorials{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">Tutorials </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                   Tutorials <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/browseObservership"
              title="Post or View Upcoming Observership"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-livesurgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/observership-white.png")}
                    alt="Observership"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/observership.png")}
                    alt="Observership"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Post / Browse upcoming
                    <br />
                    Observership Course{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">
                    Observership{" "}
                  </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                   Observership Course <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/browseConfWorkshop"
              title="Post or View Upcoming Conference/ Workshop"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/conference-white.png")}
                    alt="Conference"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/conference.png")}
                    alt="Conference"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Post / Browse upcoming
                    <br />
                    Conference / Workshop{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">
                    Conference / Workshop{" "}
                  </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                  Conference / Workshop <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/referYourPatient"
              title="Get Patient Referral/ Refer Your Patient"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/referral-white.png")}
                    alt="Patient referral"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/referral.png")}
                    alt="Patient referral"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    {/* Create your Patient referral profile / Refer your patient */}
                    {/* Refer your patient */}
                    Check Availability of Surgeons
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">
                    {/* Refer Patient{" "} */}
                    Surgeons Availability{" "}
                  </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                   Patient Referral Profile <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/myAssocGroups"
              title="My Association/ My Group"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-cme p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/association-white.png")}
                    alt="Association"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/association.png")}
                    alt="Association"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    My Association/ My Group{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">
                    Association/ group{" "}
                  </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                  Association / group <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/requestTelementorship"
              title="Request Telementorship/ Mentorship/ Online Classes/ Expert Opinion"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-surgery p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  <img
                    src={require("../../../img/mentorship-white.png")}
                    alt="CME"
                    width="44"
                    className="d-none d-sm-block"
                  />
                  <img
                    src={require("../../../img/mentorship.png")}
                    alt="CME"
                    width="44"
                    className="d-inline-block d-sm-none"
                  />
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Request Telementorship / Mentorship / Online Classes /
                    Expert Opinion{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">Mentorship </h6>
                </div>
                {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                  Mentorship <i className="fa-solid fa-arrow-right"></i>
                </Button> */}
              </div>
            </Link>
          </Col>
          <Col lg={4} className="col-4 d-flex align-items-stretch">
            <Link
              to="/live-conference-workshop"
              title="Live Conference"
              className="d-flex align-items-stretch w-100"
            >
              <div className="icon-box icon-box-dashboard icon-box-video p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                <div className="showcas_flex">
                  {/* <img src={require("../../../img/mentorship-white.png")} alt="CME" width="44" className="d-none d-sm-block"/>
                  <img src={require("../../../img/mentorship.png")} alt="CME" width="44" className="d-inline-block d-sm-none"/> */}
                  <i className="fa-solid fa-right-from-bracket me-2"></i>
                  <h6 className="mt-0 mb-0 d-none d-md-block">
                    Live Conference / <br /> Live Workshop{" "}
                  </h6>
                  <h6 className="mt-2 mb-0 d-block d-md-none ">
                    Live Conference{" "}
                  </h6>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
        <hr className="less_margin" />
      </ContainerWrapper>
      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Customized Paid Services</h2>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch">
            <Col
              lg={2}
              className="col-4 d-flex align-items-stretch mobile_hide"
            ></Col>
            <Col lg={4} className="col-4 d-flex align-items-stretch">
              <div className="bg-white px-4 py-3 rounded-3 border mb-4">
                <Link
                  to="/create-patient-referral"
                  title="Request Telementorship/ Mentorship/ Online Classes/ Expert Opinion"
                  className="d-flex align-items-stretch w-100"
                >
                  <div className="icon-box icon-box-dashboard icon-box-video p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                    <div className="showcas_flex">
                      <img
                        src={require("../../../img/referral-white.png")}
                        alt="Patient referral"
                        width="44"
                        className="d-none d-sm-block"
                      />
                      <img
                        src={require("../../../img/referral.png")}
                        alt="Patient referral"
                        width="44"
                        className="d-inline-block d-sm-none"
                      />
                      <h6 className="mt-0 mb-0 d-none d-md-block">
                        {/* Create Your Patient Referral Profile to get Referral Patient {" "}  */}
                        Create Your Customized Profile with Your Availability{" "}
                      </h6>
                      {/* <h6 className="mt-2 mb-0 d-block d-md-none ">Get Referral </h6> */}
                      <h6 className="mt-2 mb-0 d-block d-md-none ">
                        My Availability{" "}
                      </h6>
                    </div>
                    {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                        Mentorship <i className="fa-solid fa-arrow-right"></i>
                      </Button> */}
                  </div>
                </Link>
              </div>
            </Col>
            <Col lg={4} className="col-4 d-flex align-items-stretch">
              <div className="bg-white px-4 py-3 rounded-3 border mb-4">
                <Link
                  to="/add-new-patient"
                  title="Patient Registry"
                  className="d-flex align-items-stretch w-100"
                >
                  <div className="icon-box icon-box-dashboard icon-box-video p-2 p-lg-3 pb-2 pb-lg-3 rounded-3 mb-0 mb-md-4 w-100 text-center text-md-start">
                    <div className="showcas_flex">
                      <img
                        src={require("../../../img/mentorship-white.png")}
                        alt="CME"
                        width="44"
                        className="d-none d-sm-block"
                      />
                      <img
                        src={require("../../../img/mentorship.png")}
                        alt="CME"
                        width="44"
                        className="d-inline-block d-sm-none"
                      />
                      <h6 className="mt-0 mb-0 d-none d-md-block">
                        My Patient's Progress Report{" "}
                      </h6>
                      <h6 className="mt-2 mb-0 d-block d-md-none ">
                        Patient Progress{" "}
                      </h6>
                    </div>
                    {/* <Button className="p-0 btn btn-link text-left d-none d-md-block">
                        Mentorship <i className="fa-solid fa-arrow-right"></i>
                      </Button> */}
                  </div>
                </Link>
              </div>
            </Col>
            <Col
              lg={2}
              className="col-4 d-flex align-items-stretch mobile_hide"
            ></Col>
          </Row>
          <hr />
        </div>
      </section>

      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Upcoming CME</h2>
              <p>Attend live CME, view invitations and more.</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch">
            <div className="col-12">
              <div className="bg-white px-4 py-3 rounded-3 border mb-4">
                <div className="table-responsive">
                  <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      <tr>
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        <th>Status</th>
                        <th className="text-end">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardList?.matchedMeetings?.map((val, index) => (
                        <tr key={index}>
                          <td className="fw-bold">
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Topic:
                            </p>
                            <h6 className="mb-0">{val.title}</h6>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Date &amp; Time:
                            </p>
                            <p className="mb-0">
                              {/* {formatDate(val.startDate)}, */}
                              {`${moment(val.startDate).format("D MMMM YYYY")}`}
                              ,
                              <br />
                              {`${getTimeFromDate(
                                val.startDate
                              )} to ${getTimeFromDate(val.endDate)}`}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                              Hosted by:
                            </p>
                            <div className="author d-flex">
                              <span className="user-image-wrap me-2">
                                {val.profilePicture ? (
                                  <img
                                    src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.profilePicture}`}
                                    alt=""
                                    title="Surgeon Profile"
                                  />
                                ) : (
                                  <img
                                    src={require("../../../img/user.png")}
                                    alt=""
                                    title="Surgeon Profile"
                                  />
                                )}
                              </span>
                              <span className="author-details">
                                <p className="fw-bold mb-0">
                                  <Link to={`/surgeon-profile/${val.user_Id}`}>
                                    {val.fullName}
                                  </Link>
                                </p>
                                <p className="text-muted mb-2">{`${val.institution}, ${val.city}, ${val.state}, ${val.country}`}</p>
                              </span>
                            </div>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Status:
                            </p>
                            {(() => {
                              const dataResult = getStatusFromDate(
                                val.startDate,
                                val.endDate
                              );
                              return (
                                <span
                                  className={`badge badge-${dataResult.color}`}
                                >{`${dataResult.status}`}</span>
                              );
                            })()}
                          </td>
                          <td className="text-left text-lg-end">
                            <Link
                              to={`/cme-rte/${val._id}`}
                              className="mb-2 btn btn-link"
                            >
                              Details{" "}
                              <i className="fa-solid fa-arrow-right"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/attendCmeRtm"
                  title="View more"
                  className="btn btn-primary m-2"
                >
                  View More CME <i className="fa-solid fa-arrow-right"></i>
                </Link>

                <Link
                  to="/cme-rtm-invitations"
                  title="invitations"
                  className="btn btn-primary m-2"
                >
                  CME Invitations <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section>
      {/* <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Browse Live Surgery</h2>
              <p>View upcoming live surgeries</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch mobile_slide">
            {dashboardList?.matchedLiveSurgeries?.map((val, index) => (
              <Col
                key={index}
                sm={6}
                lg={6}
                xl={3}
                className="d-flex align-items-stretch"
              >
                <ThumbnailCard
                  badgeClassName="bg-surgery"
                  badgeTitle="Live Surgery"
                  feeAmount={val.fees > 0 ? `Rs. ${val.fees}` : "Free"}
                  cardTitle={val.title}
                  detailsLink={`/surgeryDetails/${val._id}`}
                  // duration={`${moment(val.startDate).format(
                  //   "D MMMM YYYY"
                  // )} to ${moment(val.endDate).format("D MMMM YYYY")}`}
                  duration={`${moment(val.startDate).format(
                    "D MMMM YYYY"
                  )} at ${getTimeFromDate(val.startDate)} to ${getTimeFromDate(val.endDate)}`}
                  // duration={`${formatDate(val.startDate)} to ${formatDate(
                  //   val.endDate
                  // )}`}
                  location={`${val.userCity}, ${val.userState}, ${val.userCountry}`}
                  doctorName={`Dr. ${val.userName}`}
                  doctorAddress={val.userInstitution}
                  buttonTitle={"Register Now"}
                  buttonAction={""}
                  id={val._id}
                  doctorId={val.userId}
                  profilePicture={val.profilePicture}
                  enroll={true}
                  isHost={ currentUser == val.userId ? true : false}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/browseLiveSurgery"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Live Surgeries{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section> */}
      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Latest Surgery Videos.</h2>
              <p>Latest surgery videos.</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch mobile_slide">
            {dashboardList?.matchedVideos?.map((val, index) => (
              <Col
                key={index}
                sm={6}
                lg={6}
                xl={3}
                className=" d-flex align-items-stretch"
              >
                <ThumbnailCard
                  badgeClassName="bg-video"
                  badgeTitle="Video"
                  cardTitle={val.title}
                  detailsLink={`/video-details/${val._id}`}
                  cardId={val._id}
                  doctorName={`Dr. ${val.fullName}`}
                  doctorAddress={`${val.institution}, ${val.city}, ${val.state}, ${val.country}`}
                  viewsCount={`${val.views} Views`}
                  // noOfDays={val.uploaded}
                  noOfDays={timeAgo(val.createdAt)}
                  mediaType={val.urlType}
                  url={val.url}
                  profilePicture={val.profilePicture}
                  doctorId={val.userId}
                  isHost={false}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/browseSurgeryVideos"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Surgery Videos{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section>
      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Latest Tutorials.</h2>
              <p>Latest Tutorials.</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch mobile_slide">
            {dashboardList?.matchedTutorials?.map((val, index) => (
              <Col
                key={index}
                sm={6}
                lg={6}
                xl={3}
                className=" d-flex align-items-stretch"
              >
                <ThumbnailCard
                  badgeClassName="bg-tutorial"
                  badgeTitle="Tutorial"
                  cardTitle={val.title}
                  detailsLink={`/tutorial-details/${val._id}`}
                  cardId={val._id}
                  doctorName={`Dr. ${val.fullName}`}
                  doctorAddress={`${val.institution}, ${val.city}, ${val.state}, ${val.country}`}
                  viewsCount={`${val.views} Views`}
                  // noOfDays={val.uploaded}
                  noOfDays={timeAgo(val.createdAt)}
                  mediaType={val.urlType}
                  url={val.url}
                  profilePicture={val.profilePicture}
                  doctorId={val.userId}
                  isHost={false}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/browseTutorials"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Tutorials{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section>

      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Browse Observership</h2>
              <p>View upcoming observerships</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch mobile_slide">
            {dashboardList?.matchedObserverships?.map((val, index) => (
              <Col
                key={index}
                sm={6}
                lg={6}
                xl={3}
                className="d-flex align-items-stretch"
              >
                <ThumbnailCard
                  badgeClassName="bg-surgery"
                  badgeTitle="Observership"
                  detailsLink={`/observershipDetails/${val._id}`}
                  feeAmount={`Rs.${val.observershipFee}`}
                  cardTitle={val.observershipName}
                  duration={`${moment(val.startDate).format(
                    "D MMMM YYYY"
                  )} to ${moment(val.endDate).format("D MMMM YYYY")}`}
                  location={`${val.city}, ${val.state}, ${val.country}`}
                  doctorName={`Dr. ${val.facultyName}`}
                  doctorAddress={`${val.facultyCity}, ${val.facultyState}, ${val.facultyCountry}`}
                  buttonTitle={"Register Now"}
                  buttonAction={""}
                  id={val._id}
                  profilePicture={val.profilePicture}
                  doctorId={val.userId}
                  enroll={true}
                  isHost={false}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/browseObservership"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Observership{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section>

      {/* <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Upcoming Conferences</h2>
              <p>View upcoming conferences.</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch">
            <div className="col-12">
              <div className="bg-white px-4 py-3 rounded-3 border mb-4">
                <div className="table-responsive">
                  <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                    <thead className="">
                      <tr>
                        <th width="40%">Topic</th>
                        <th width="20%">Date &amp; Time</th>
                        <th width="20%">Posted By</th>
                        <th width="10%">Location</th>
                        <th className="text-end">&nbsp;</th>
                      </tr>
                     
                      
                    </thead>
                    <tbody>
                      {dashboardList?.matchedConferences?.map((val, index) => (
                        <tr key={index}>
                          <td className="fw-bold">
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Topic:
                            </p>
                            <h6 className="mb-0">{val.title}</h6>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Date &amp; Time:
                            </p>
                            <p className="mb-0">
                              {`${moment(val.conferenceDate).format("D MMMM YYYY")} to ${moment(val.conferenceEndDate).format("D MMMM YYYY")}`}
                            </p>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                              Location:
                            </p>
                            <div className="author d-flex">
                              <span className="user-image-wrap me-2">
                               {val.profilePicture ? (
                                  <img
                                    src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val.profilePicture}`}
                                    alt=""
                                    title="Surgeon Profile"
                                  />
                                ) : (
                                  <img
                                    src={require("../../../img/user.png")}
                                    alt=""
                                    title="Surgeon Profile"
                                  />
                                )}
                              </span>
                              <span className="author-details">
                                <p className="fw-bold mb-0">
                                  <Link to={`/surgeon-profile/${val.userId}`}>
                                    {val.userName}
                                  </Link>
                                </p>
                                <p className="text-muted mb-2">{`${val.conferencesCity}, ${val.conferencesState}, ${val.conferencesCountry}`}</p>
                              </span>
                            </div>
                          </td>
                          <td>
                            <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                              Location:
                            </p>
                            <span>
                              {`${val.userCity}, ${val.userState}, ${val.userCountry}`}
                            </span>
                          </td>
                          <td className="text-left text-lg-end">
                            <Link
                              to={`/confDetails/${val._id}`}
                              className="mb-2 btn btn-link"
                            >
                              Details{" "}
                              <i className="fa-solid fa-arrow-right"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/browseConfWorkshop"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View Conference <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section> */}

      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Browse Conferences</h2>
              <p>View upcoming conferences.</p>
            </div>
          </Row>
          <Row className="d-flex align-items-stretch mobile_slide">
            {dashboardList?.matchedConferences?.map((val, index) => (
              <Col
                key={index}
                sm={6}
                lg={6}
                xl={3}
                className="d-flex align-items-stretch"
              >
                <ThumbnailCard
                  badgeClassName="bg-surgery"
                  badgeTitle="Conference"
                  detailsLink={`/confDetails/${val._id}`}
                  feeAmount={`Rs. ${val.fees}`}
                  cardTitle={val.title}
                  duration={`${moment(val.conferenceDate).format(
                    "D MMMM YYYY"
                  )} to ${moment(val.conferenceEndDate).format("D MMMM YYYY")}`}
                  location={`${val.conferencesCity}, ${val.conferencesState}, ${val.conferencesCountry}`}
                  doctorAddress={`${val.userCity}, ${val.userState}, ${val.userCountry}`}
                  doctorName={`Dr. ${val.userName}`}
                  buttonTitle={"Register Now"}
                  buttonAction={(url) => {
                    registerConferenceNow(url);
                  }}
                  // id={val._id}
                  doctorId={val.userId}
                  profilePicture={val.profilePicture}
                  isHost={false}
                  enroll={true}
                  id={val.url}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/browseConfWorkshop"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Conference{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
          <hr />
        </div>
      </section>
      <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Associations and Groups</h2>
              <p>Join surgery associations and groups.</p>
            </div>
          </Row>
          <Row>
            {dashboardList?.matchedAssociations?.map((val, index) => (
              <Col
                key={index}
                sm={4}
                lg={4}
                xl={2}
                className="col-6 d-flex align-items-stretch"
              >
                <div className="bg-white text-center p-4 rounded-3  bg-white border w-100 mb-4">
                  <Link title="" to={`/AssociationDetails/${val._id}`}>
                    {val?.group_image ? (
                      <img
                        src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${val?.group_image}`}
                        alt=""
                        title="Association Logo"
                        className="img-fluid mb-3"
                      />
                    ) : (
                      <img
                        src={AssociationLogo}
                        alt=""
                        title="Association Logo"
                        className="img-fluid mb-3"
                      />
                    )}

                    {/* <img
                          src={AssociationLogo}
                          alt=""
                          className="img-fluid mb-3"
                        /> */}

                    <h6 className="fw-bold mb-0">{val.association_name}</h6>
                  </Link>
                </div>
              </Col>
            ))}
            <div className="col-12 mb-4">
              <div className="text-center">
                <Link
                  to="/BrowseAssocGroups"
                  title="View more"
                  className="btn btn-primary mb-2"
                >
                  View More Associations{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
                <Link
                  to="/createAssocGroups"
                  title="View more"
                  className="btn mb-2"
                >
                  Create A Group <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>

          <hr />
        </div>
      </section>
      {/* <section className="pb-4">
        <div className="container">
          <Row>
            <div className="col-12">
              <h2 className="h4 mb-1">Refer Your Patients</h2>
              <p>Refer your patients to top surgeons across the world.</p>
            </div>
          </Row>
          <Row>
            {dashboardList?.matchedReferrals?.map((val, index) => (
              <Col
                key={index}
                sm={4}
                lg={4}
                xl={2}
                className="col-6 d-flex align-items-stretch"
              >
                <div className=" bg-white text-center p-4 rounded-3  bg-white border w-100 mb-4">
                  <Link
                    to="/"
                    title=""
                    className="user-image-wrap user-image-lg mb-2"
                  >
                    <img src={surgeonPic} alt="" className="img-fluid mb-3" />
                  </Link>
                  <h6 className="fw-bold">
                    <Link href="#" title="">
                      {val.fullName}
                    </Link>
                  </h6>
                  <p>
                    {val.designation}, <br />
                    {val.institution}, {val.city}, {val.state}, {val.country}
                  </p>
                  <Link to="/" className="btn btn-primary mb-2">
                    Refer Patient <i className="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </Col>
            ))}
            <div className="col-12 mb-2">
              <div className="text-center">
                <Link
                  to="patient-referral-profile.html"
                  title="View more"
                  className="btn btn-primary"
                >
                  Create Patient Referral Profile{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
                <Link
                  to="/referYourPatient"
                  title="View more"
                  className="btn btn-link"
                >
                  View More Surgeons <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </Row>
        </div>
      </section> */}
    </Root>
  );
};

export default Dashboard;
